.bannerContentSection {
    position: relative;
    height: 100%;
  }
  
  .bannerImage {
    width: 100%;
    height: auto;
    object-fit: cover;
    background-color: var(--white-color);
  }
  
  .newGradientSectionClass {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
  }
  
  .newBannerTextClass {
    justify-content: center;
    gap: 10%;
  }

  .absoluteGradientDiv {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }