.sortName {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.08px;
    color: var(--black-color);
    text-transform: uppercase;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
    gap: 12.5px;
}