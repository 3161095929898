.countdown_container {
    font-family: Arial, sans-serif;
    display: flex;
    gap:20px;
  /*   flex-direction: column; */
    align-items: center;
    /* padding: 20px; */
  /*    background-color: #f0f0f0; */
    /* border-radius: 10px; */
  /*    max-width: 600px; */
    margin: 0 auto;
    background-color: #FC3300;
    color: white;
    display: flex;
    padding: 20px;
    justify-content: space-between;
  }

  .countdown_header {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .countdown_timer {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
  }

  .countdown_item {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    min-width: 80px;
    font-weight: 700;
    font-size: 48;
    line-height: 38px;
  }

  .countdown_value {
    font-size: 3rem;
    font-weight: bold;
    /* width: 71%; */
  }

  .countdown_label {
    font-size: 0.8rem;
    font-weight: 600;
    line-height: 20px;
  }

  .countdown_footer {
    font-size: 1rem;
    margin-top: 10px;
  }

  .discountPercentage{
    font-size: 1.3rem;
    font-weight: bold;
    margin-top: 10px;
    width: 20%;
    text-align: center;
  }

  .title{
    width: 30%;
  }

  .discountPercentageMobile{
    display: none;
  }

  @media (max-width: 900px) {
    .discountPercentageMobile{
      display: flex;
      font-size: 1.2rem;
      font-weight: bold;
      text-align: center;
      margin: 10px 0;
    }

    .discountPercentage{
      display: none;
    }
    .countdown_container {
      padding: 10px;
      gap: 0;
      flex-direction: column;
    }

    .countdown_container > div:first-child {
      width: 100%;
    }
  }

  @media (max-width: 600px) {
    .countdown_header {
      font-size: 1.5rem;
    }

    .countdown_timer {
      display: flex;
      justify-content: space-evenly;
      width: 100%;
      /* gap: 0; */
    }

    .countdown_item {
      min-width: 60px;
    }

    .countdown_value {
      font-size: 38px;
      width: 100%;
    }

    .days_value {
      font-size: 28px;
      margin-bottom: 5px;
    }

    .countdown_label {
      font-size: 0.7rem;
    }
  }