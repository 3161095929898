.bannerContentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.bannerHeading {
    font-size: 56px;
    font-weight: 400;
    line-height: 61.6px;
    color: var(--pure-white);
}

.bannerPara {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    color: var(--pure-white);
}

.headerContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.filtersSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
}

.filters, .filterButtons, .searchAndSortContainer {
    display: flex;
    gap: 24px;
    align-items: center;
}

.filterButtons {
    overflow-x: auto;
}

.filters {
    width: 60%;
}

.filterHeading {
    color: var(--black-color) !important;
    min-width: fit-content;
}

.searchAndSortContainer {
    justify-content: flex-end;
    width: fit-content;
    flex-direction: row-reverse;
}

.searchBarMobile {
    display: none;
}

.sortButton {
    padding: 12px 16px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.08px;
    border: .6px solid var(--line-color);
    color: var(--black-color);
}

.sortIcon {
    transform: rotate(90deg);
}

.filtersOutputSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    flex-wrap: wrap;
    row-gap: 24px;
}

.filterdOutputContainer {
    display: flex;
    align-items: center;
    gap: 24px;
}

.filterdOutputs {
    max-width: 60vw;
    overflow-x: auto;
    display: flex;
    gap: 24px;
    align-items: center;
}

.outPut {
    padding: 12px 16px;
    border-radius: 25px;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.08px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    width: 100%;
   background-color: var(--off-white);
}

.outSpan {
    width: max-content;
    text-transform: uppercase;
}

.clearSingleIcon {
    cursor: pointer;
}

.clearAllButton {
    border: none;
    background-color: transparent;
    font-size: 14px;
    font-weight: 700;
    line-height: 17.08px;
}

.clearAllButton > span {
    display: flex;
    width: max-content;
    color: var(--black-color);
}

.itemNumberPara {
    font-size: 16px;
    line-height: 25.6px;
    font-weight: 400;
    color: var(--off-black);
}

.line {
    width: 100%;
    color: var(--line-color);
}

.productsSection {
    display: flex;
    flex-direction: column;
    /* gap: 80px; */
    gap: 70px;
    justify-content: center;
    margin-top: 70px;
}

.productsGridContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    row-gap: 70px;
    column-gap: 30px;
}

.shopNowPagination {
    margin-top: 0;
    padding-bottom: 0;
}

.progressContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 500px;
}

.noProductsContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.noProductsText {
    color: var(--black-color);
    font-weight: 700;
}

.productSectionLine {
    color: var(--line-color);
    margin-inline: 0 !important;
    width: 100%;
}

.rightArrow{
    display: none;
}

@media screen and (min-width:1151px) {
    .searchBar {
        width: 400px;
    }
}

@media screen and (max-width:1380px) {
    .productsGridContainer {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width:1150px) {
    .productsGridContainer {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width:950px) {
    .filterHeading {
        font-size: 38px !important;
        line-height: 26px !important;
    }
    .sortButton {
        font-size: 14px;
        padding: 7px 14px;
        gap: 2px;
    }
    .sortIcon {
        font-size: 20px !important;
    }
}

@media screen and (max-width:880px) {
    .filters {
        width: auto;
    }
    .searchBar {
        display: none;
    }
    .searchBarMobile {
        display: block;
        /* margin-top: 13px; */
    }
}

@media screen and (max-width: 768px) {
    .productsSection {
        margin-top: 30px;
    }
    .filtersSection {
        gap: 24px;
    }
    /* .sortButton {
        width: 100%;
        flex-direction: row-reverse;
        justify-content: space-between;
        gap: 24px;
    } */
    .filterdOutputContainer {
        flex-wrap: wrap;
    }
    .filterdOutputs {
        overflow-x: hidden;
        flex-wrap: wrap;
        max-width: 100%;
    }
    .outPut{
        width: auto;
    }
    .outSpan {
        width: auto;
    }
}

@media screen and (max-width:680px) {
    .filters, .filterButtons {
        gap: 9px;
    }
}

@media screen and (max-width:660px) {
    .productsGridContainer {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width:610px) {
    .filtersSection {
        gap: 10px;
    }
    .filters {
        width: 77%;
    }
    .filterHeading {
        font-size: 24px !important;
        line-height: 22px !important;
        min-width: 63px;
    }
    .sortButton {
        font-size: 11px;
        padding: 3px 10px;
        gap: 1px;
    }
    .sortIcon {
        font-size: 15px !important;
    }
}


@media screen and (max-width:540px) {
    .bannerHeading {
        font-size: 36px;
        line-height: 39.6px;
    }
}

@media screen and (max-width:490px) {
    .filterButtons {
        overflow-x: auto;
    }
    .filters, .filterButtons {
        gap: 4px;
    }
    .rightArrow{
        display: block;
    }
}