.inputContainer {
    width:100%
}

.inputClass {
    border: 1px solid var(--black-color) !important;
    background-color: var(--pure-white) !important;
    height: 48px;
    padding: 12px;
    border-radius: 5px;
    font-size: 14px;
    width: 100%;
}

.label {
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 5px;
}

.subscribeForm {
    background-color: var(--pure-white) !important;
    border-radius: 1px !important;
}