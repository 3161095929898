.customer_support_section{
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 1px;
}

.customer_support {
    display: flex;
    flex-direction: column;
    gap: 35px;
    padding-inline:30px;
    /* background-color:#30313A; */
    color:#F0E8E3;
    gap: 0;
    justify-content: space-between;
    padding-top: 170px;
    padding-bottom: 30px;
}
.customer_support > h1 {
    font-weight: 800;
    font-size: calc(1.275rem + .3vw);
}
.customer_support > .part {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.customer_support > .part > p {
    margin: 0;
}
.customer_support > div > .part > p {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.36px;
}

.customer_support_section> .footer-extra-container-class-30313A{
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

@media screen and (max-width:768px){
    .customer_support{
        min-height: 80vh;
    }
}

@media screen and (min-width:769px){
    .customer_support{
        padding: 200px 50px 0;
    }
}