:root {
    --primary-backgroundcolor: var(--pure-white);
}

.faq_main {
    padding: 15px 25px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 150px;
}
.faq_block > h4 {
    margin: 25px 0;
    font-weight: 800;
}
.faq_block > ol {
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-weight: 300;
}
.faq_mini_wraper > h5 {
    margin-bottom: 15px;
}

.about-container{
    height:fit-content;
    background-color: var(--primary-backgroundcolor);
    padding: 5vw;
    padding-top: 155px;
    /* color: rgba(252, 51, 0, 1); */
    /* font-family: "Instrument Sans"; */
}

.ab-heading{
    font-size: 42px;
    font-weight: 400;
    line-height: 52px;
    text-align: justify;
    margin-bottom: 25px;
}

.ab-sub-heading{
    font-size: 32px;
    font-weight: 400;
    line-height: 35px;
}

.ab-common-style{
    font-size: 18px;
    font-weight: 400;
     margin: 20px 0;
     line-height: 24px;
}

.team-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 20px;
}
.about-container .profile{
    width: 140px;
}
.about-container .profile img{

    margin-bottom: 15px;
    width: 100%;
    height: 140px;
    border-radius: 20px;
    object-fit: cover;
    object-position: top;
}
.about-container .profile .description p{
    margin-bottom: 0;
}
.about-container .profile .description .name{
    font-size: 18px;
    font-weight: 700;
}
.about-container .profile .description .designation{
    font-size: 12px;
    font-weight: 400;
}
@media screen and (min-width: 768px) {
    .support_main {
        width: 85vw;
        margin: 0 auto;
    }
    .dabout_image_container {
        width: 100%;
        height: 405px;
    }
    .dabout_image_container > img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .dabout_part {
        font-weight: 600;
    }

    .about-container{
        padding-top: 170px;
    }

    .ab-heading{
        font-size: 70px;
        font-style: normal;
        line-height: 75px;
        /* letter-spacing: 1.4px; */
        margin-bottom: 33px;
    }
}

@media screen and (min-width:980px) {
    .about-container .profile{
        width: 190px;
    }
    .about-container .profile img{
        height: 190px;
        width: 100%;
    }
}