.shipping-information-container {
    padding-top: 170px;
    background-color: white;
}
.shipping-info__heading{
    /* font-family: "AvenirNext"; */
    font-weight: 800;
    font-size: 21px;
    margin-bottom: 24px;
    /* text-align: center; */
}

.shipping-info_sub_heading {
    /* font-family: "AvenirNext"; */
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 10px;
}

.shipping-information-container input[type="text"], input[type="number"], .select-input, .shipping-info-phone input {
    height: 48px;
    padding: 12px;
    margin-bottom: 20px;
    /* border: 1px solid rgba(0, 0, 0, 0.23); */
    border: none;
    border-radius: 5px;
    background-color: #F4F4F4;
    width: 100%;
    font-size: 14px;
}

.shipping-information-container input[type="text"] input[type="number"]::placeholder {
    color: #7C7575;
    font-size: 14px;
}

.shipping-information-container input[type="text"] input[type="number"]:focus {
    outline: 2px solid #3186d1;
}

.shipping-info_half_inp {
    display: flex;
    justify-content: space-between;
}

.shipping-info_half_inp input {
    width: 48%;
}

.shipping-information-container input[type = "radio"], .select-input {
    /* height: 48px; */
    padding: 12px;
    margin-bottom: 20px;
    /* border: 1px solid rgba(0, 0, 0, 0.23); */
    border: none;
    border-radius: 5px;
    background-color: #F4F4F4;
    /* width: 100%; */
    font-size: 14px;
}

.shipping-information-container input[type = "radio"]::placeholder {
    color: #7C7575;
    font-size: 14px;
}

.shipping-info-delivery {
    margin-bottom: 30px;
}

@media screen and (min-width: 768px) {
    .shipping-information-container {
        width: 65vw;
        background-color: white;
        margin: 20px auto;
    }
}

@media screen and (max-width:768px) {
    .shipping-information-container {
        padding-top: 60px;
        padding-inline: 20px;
    }
    .shipping-info__heading {
        text-align: start;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        line-height: 25px;
        letter-spacing: 0.4px;
    }
    .shipping-info_sub_heading {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: -0.36px;
    }

    .shipping-information-container input[type="text"], input[type="number"], .select-input, .shipping-info-phone input {
        display: flex;
        padding: 14px 20px 14px 10px;
        align-items: center;
        gap: 10px;
        border-radius: 8px;
        margin-bottom: 23px;
        font-family: 'Instrument Sans';
    }

    .shipping-information-container input[type="text"] input[type="number"]::placeholder {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        font-family: 'Instrument Sans';
    }
}