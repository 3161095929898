.aboutUsContainer {
    display: flex;
    gap: 70px;
}

.aboutUsLeft {
    width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
}

.aboutUsTitle {
    width: min-content;
    color: var(--black-color) !important;
}

.leftBottom {
    display: flex;
    gap: 4px;
    flex-direction: column;
}

.leftBottomHead {
    font-size: 20px;
    font-weight: 400;
    line-height: 32px;
    color: var(--black-color);
}

.leftBottomPara {
    font-weight: 400;
    font-size: 14px;
    line-height: 22.4px;
    color: var(--black-color);
}

.leftAction {
    display: flex;
    gap: 16px;
    align-items: center;
    margin-top: 20px;
}

.nonProfitsLinks {
    color: var(--red-color);
    font-size: 12px;
    font-weight: 600;
    line-height: 17.08px;
    text-align: center;
}

.aboutUsRight {
    width: 100%;
    height: 628px;
}

.mainImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (min-width: 1500px) {
    .aboutUsLeft {
        width: 480px;
    }
}

@media screen and (min-width:1750px) {
    .aboutUsLeft {
        width: 580px;
    }
}

@media screen and (max-width:1100px) {
    .aboutUsContainer {
        flex-direction: column;
        gap: 32px;
    }
    .aboutUsLeft {
        width: 100%;
        gap: 32px;
    }
    .aboutUsRight {
        height: auto;
    }
}
