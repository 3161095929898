ul {
    padding-left: 0 !important;
}

p {
    margin-bottom: 0 !important;
}

li {
    margin-bottom: 15px !important;
}

.footerContainer {
    display: flex;
    gap: 80px;
    flex-direction: column;
}
.footerTopPart {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 100px;
}
.topLogoSide {
    display: flex;
    flex-direction: column;
    gap: 60px;
    width: 40%;
}
.logoSection {
    display: flex;
    flex-direction: column;
    gap: 40px;
}
.logoContainer {
    width: 60px;
    height: 50px;
}
.logoPara, .addressPara {
    font-size: 14px;
    font-weight: 400;
    line-height: 22.4px;
    color: var(--black-color);
}

.newsLetterSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.newsLetterPart {
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.newsLetterTitle {
    color: var(--black-color) !important;
    font-size: 32px !important;
    line-height: 24px !important;
}
.newsLetterForm {
    display: flex;
    gap: 16px ;
    align-items: center;
    width: 100%;
}

.topMenuAndAddressSide {
    width: 60%;
    display: flex;
    justify-content: space-between;
}

.menuPart, .addressPart {
    display: flex;
    gap: 40px;
    flex-direction: column;
}

.footerLink, .addressHeading, .copyRightText {
    font-weight: 600;
    font-size: 14px;
    line-height: 17.08px;
    color: var(--black-color);
    text-transform: uppercase;
    list-style: none;
}

.addressSection {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.address {
    display: flex;
    gap: 4px;
    flex-direction: column;
}

.footerBottomPart {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.copyRightText {
    text-transform: uppercase;
    text-align: center;
}

.termsAndPolicy {
    display: flex;
    gap: 20px;
    align-items: center;
}

.socialIcons {
    display: flex;
    gap: 20px;
}
.socialIcons> a {
    cursor: pointer;
}

.icon {
    height: 24px;
    width: 24px;
}

@media screen and (max-width:980px) {
    .footerTopPart {
        flex-direction: column;
        gap: 40px;
    }
    .topLogoSide, .topMenuAndAddressSide {
        width: 100%;
    }
    .logoSection {
        gap: 30px;
    }
}

@media screen and (max-width: 980px) {
    .footerBottomPart {
        flex-direction: column;
        justify-content: center;
        gap: 32px;
    }
}
@media screen and (max-width: 540px) {
    .newsLetterForm {
        flex-direction: column;
        gap: 20px;
    }
    .termsAndPolicy {
        flex-direction: column;
    }
}