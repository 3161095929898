.register-main{
  min-height: 100vh;
  background-color: #FC3300;
}

.register-container{
    min-height: 100%;
    background-color: #FC3300;
    font-family: "Instrument Sans";
    /* padding-bottom: 50px; */
    text-align: center;
    display: flex;
    flex-direction: column;
    /* gap: 38px; */
    padding-block: 35px;
}
.register-logo{
    padding-top: 5%;
    width: 100%;
    /* height: 100px; */
    margin-bottom: 30px;
    text-align: center;
}
.register-logo  img{
    width: 80%;
}
.register-form-container{
    text-align: left;
    background-color: #F0E8E3;
    width: 85%;
    border-radius: 25px;
    margin: auto;
    padding: 20px;
    color:#FC3300;
    margin-bottom: 25px;
}
.register-form-container .create-account{
  text-align: center;
  font-size: 32px;
  line-height: 34px;
  font-weight: 400;
}
.register-form-container > .sub-heading{
  font-size: 12px;
  margin-bottom: 22px;
}
.register-form-control{
    width: 100%;
    height:38px ;
    padding-left: 10px;
    border:1px solid #FC3300;
    border-radius: 5px;
    background-color: #F0E8E3;
    font-size: 14px;
}
.register-form-control::placeholder{
    color: #FC3300;
    /* padding-left:10px; */
}

#emailUpadte , #emailPolicy{
  height: 14px;
  width: 14px;
  top: 5px;
    color:#FC3300;
    border: 1px solid #FC3300;
}

.register-block-btn{
  width: 85%;
  height: 40px;
  /* border: 2px solid #F0E8E3;
  color: #F0E8E3; */
  border-radius: 20px;
  outline: none;
  font-weight: 600;
  font-size: 16px;
  margin: auto;
  border: 2px solid #FC3300;
  color: #FC3300;
  display: flex;
  justify-content: center;
  align-items: center;
}

.register-block-btn.not-allowed-btn {
    background: #e6e7ea;
    color: #a2a4aa;
    border: 1px solid #FC3300;
  }

  .register-blockBtn-loading {
    /* background: var(--primary-blue-clr); */
    width: 100%;
    height: 40px;
    border: none;
    color: #fff;
    border-radius: 20px;
    padding: 5px;
    outline: none;
    font-weight: 700;
    font-size: 13px;
    margin-top: 15px;
  }

  .register_subheading {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}
.stickDown h6 span {
  font-size: 12px;
  margin-inline: 2px;
  text-decoration: underline !important;
  text-decoration-color: white;
  cursor: pointer;
}

.required_text {
  font-size: 13px;
  color: #FC3300;
  position: relative;
  top: 20px;
}

.register-form-container .pw-error-label{
  font-size: 12px;
  text-align: left;
}

.common_font {
  font-size: 17px !important;
}

.error_register {
  text-align: center !important;
  /* padding-bottom: 15px; */
}

  @media only screen and (max-width: 380px) {
    .register-container{
        height: 100%;
    }
  }

  @media only screen and (min-width: 768px) {
    .required_text {
      top: 40px;
    }
    .register-container{
      gap: 10px;
    }
    .register-logo{
      max-width: 400px;
      margin: auto;
      height: fit-content;
      padding-top: 0px;
      /* padding-bottom: 100px; */
    }
    .register-logo  img{
      width: 100%;
      height: 75%;
      object-fit: contain;
    }
    .register-form-container{
      margin-top: 50px;
      max-width: 500px;
      padding: 50px;
      text-align: center;
      position: relative;
    }
    .register-form-container  p{
      text-align: justify;
      /* padding-bottom: 15px; */
    }
    .register-form-container .create-account{
      text-align: center;
      font-size: 35px;
    }
    .register-form-container > .sub-heading{
      font-size: 16px;
      margin-bottom: 22px;
    }
    .register-form-control{
      height: 45px;
      font-size: 16px;
    }
    .register-block-btn{
      max-width: 200px;
    }
  }

  @media screen and (max-width:500px) {
    .common_font {
      font-size: 15px !important;
    }
  }