.eventDetailsCard {
    color: var(--red-color) !important;
    font-size: 24px !important;
    text-align: center !important;
    height: auto !important;
    margin-bottom: 0 !important;
}

.featuredItemCard {
    color: var(--black-color) !important;
    font-family: 'Instrument Sans' !important;
    height: auto !important;
}

.donationLabel {
    font-family: 'Instrument Sans' !important;
    font-size: 9px !important;
    font-weight: 600 !important;
    line-height: 9.76px !important;
    letter-spacing: 5% !important;
    margin-bottom: 0;
}

@media screen and (max-width:768px) {
    .eventDetailsCard {
        font-size: 16px !important;
    }
}