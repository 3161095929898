.productCardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 350px;
    align-items: flex-start;
    margin-inline: auto;
    cursor: pointer;
    /* transition: scale 0.3s ease-in-out; */
}
/* .productCardContainer:hover {
   scale: 98%;
   transition: scale .3s;
} */

.shopNowCardContainer {
    max-width: 290px;
}

.imageContainer {
    height: 350px;
    width: 350px;
    position: relative;
}

.shopNowImageContainerClass {
    height: 290px;
    width: 290px;
}

.imageContainer>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.cardContent {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 30px;
}

.itemName {
    color: var(--black-color) !important;
}

.shopNowItemName {
    font-size: 32px !important;
    line-height: 24px !important;
    word-break: break-word;
}

.priceContainer {
    display: flex;
    flex-direction: column;
    /* gap: 5px; */
}

.listPriceContainer {
    display: flex;
    gap: 5px;
}

.listPriceContainerStrike {
    display: flex;
    gap: 5px;
    font-size: 14px;
    color: #999;
    text-decoration: line-through;
}

.listPriceValue, .listPriceLabel {
    font-size: 16px;
    font-weight: 500;
    line-height: 22.4px;
}

.itemPriceLabel {
    font-weight: 400;
    font-size: 16px;
    line-height: 25.6px;
}

.itemPriceValue {
    font-weight: 600;
    font-size: 16px;
    line-height: 25.6px;
}

.donationBox {
    padding: 10px;
    border: 1px solid var(--red-color);
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 60px;
    top: 77%;
    left: 6%;
    background-color: var(--pure-white);
}

.donationValue {
    font-family: 'Instrument Sans Condensed Bold';
    font-size: 24px;
    line-height: 21.6px;
    font-weight: 700;
    color: var(--red-color);
}

.itemRedAlert {
    color: var(--red-color);
}

@media screen and (max-width:767px) {
    .shopNowItemName {
        font-size: 48px !important;
        line-height: 36px !important;
    }
}

@media screen and (max-width:660px) {
    .shopNowCardContainer {
        max-width: 345px;
    }
    .shopNowImageContainerClass {
        width: 100%;
        height: 345px;
    }
}