:root {
  --primary-blue-clr: #30313A;
  --new-charcol-clr: #30313A;
  --shady-white: #F0E8E3;
  --pure-white:'#ffffff'
}

.buyNow-from-header {
  /* font-family: "AvenirNext"; */
  letter-spacing: 0.2px;
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: 20px;
  padding: 0 6px;
}
.cause_container{
  height: 50px;
  background-color: #D4B4B880;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 28px 0;
}
.cause_container > p{
  margin-bottom: 0;
  font-weight: 700;
  font-size: 18px;
  text-align: center;
}

.browse-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.browse-page .browse-items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: 70px;
  column-gap: 30px;
}

/* .browse-page-container {
  padding: 5vw 5vw;
  padding-bottom: 25vw;
} */

.search-bar {
  margin-top: 12px;
}

.search-bar .MuiPaper-root {
  box-shadow: none;
  padding-left: 12px;
}

.items-control {
  display: flex;
  justify-content: space-between;
  padding: 0 6px;
  margin: 12px 0 8px;
  align-items: flex-start;
}

.items-count {
  font-size: 13px;
  font-weight: 600;
}

.filter-btn {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.filter-btn > img {
  /* height: 20px;
  width: 20px; */
  height: 18px;
  width: 18px;
  transition: transform 0.6s;
}

.filter-btn__text {
  font-weight: 600;
  font-size: 14px;
  color: #5a5a5a;
  margin-left: 6px;
}

.filter-screen {
  display: flex;
  flex-direction: column;
  margin-top: 15%;
  height:70%;
  justify-content: space-between;
}

.filter-heading {
  background-color: white;
}

.filter-heading button {
  padding: 0;
}

.filter-screen .global-header__action-btn {
  font-family: 'Instrument Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  /* color: #7b7b7b; */
  padding-left: 6px;
}

.filter-screen .MuiTypography-root.MuiTypography-body1 {
  color: var(--new-charcol-clr);
  font-family: 'Instrument Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  /* letter-spacing: 0.36px; */
}

.filter-screen .MuiSlider-root {
  color: var(--primary-blue-clr);
  padding: 0;
}

.filter-screen .MuiButtonBase-root {
  padding-top: 0;
  padding-bottom: 0;
}

.filter-screen .MuiButtonBase-root.Mui-checked {
  /* color: var(--primary-blue-clr); */
  color: var(--new-charcol-clr);
}

.filter-control-wrapper {
  padding-inline: 12px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.filter-control-wrapper .MuiSvgIcon-root {
  font-size: 18px;
}

.MuiFormLabel-root.filter-control-heading {
  color: var(--new-charcol-clr);
  font-family: 'Instrument Sans';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0.36px;
  margin-bottom: 16px;
}

/* .MuiFormLabel-root.Mui-focused.filter-control-heading {
  color: #7b7b7b;
}

.MuiFormLabel-root.filter-control-heading.price-range {
  font-size: 14px;
  line-height: 14px;
} */

.price-slider-wrapper {
  padding-left: 8px;
  padding-right: 8px;
}
.price-slider-wrapper .MuiSlider-root{
  color: var(--new-charcol-clr) !important;
}

.slider-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}

.slider-label-item {
  /* font-family: "AvenirNext"; */
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 11px;
}

.filter-apply-wrapper {
  padding: 24px;
}

.apply-filter-btn {
  background-color: var(--primary-btn-clr);
  color: white;
  border-radius: 6px;
  height: 36px;
  width: 100%;
}

.MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
  width: 60%;
  overflow: hidden;
  background-color: var(--pure-white);
}

.browse-items .no-data {
  width: 100%;
  padding-top: 15vh;
  text-align: center;
}

.no-data {
  width: 100%;
  padding-top: 15vh;
  text-align: center;
}

.browse-page .auction_society {
  padding: 20px 6px 0;
}

.browse-page .auction_society a {
  position: inherit;
}

.browse-page .auction_links {
  padding: 6px;
}

.don_auccor {
  font-size: 16px !important;
  font-weight: 600 !important;
  box-shadow: none !important;
  border: none !important;
  margin: 10px 0 25px 0 !important;
}
.don_auccor > div {
  padding: 0 6px !important;
  min-height: 0 !important;
}
.don_auccor::before {
  display: none !important;
}
.don_auccor > div > div {
  margin: 0 !important;
}
.don_auccor_details {
  padding: 0 !important;
  padding-top: 10px !important;
  font-size: 14px !important;
  font-weight: 300 !important;
}

.mobile_filter_header {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 30px;
  align-items: center;
  margin-bottom: 30px
}
.filters_heading {
  font-size: 42px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px; /* 125% */
  letter-spacing: 0.4px;
}

.reset_button {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 150% */
  cursor: pointer;
}

.css-1iidsfk-MuiFormLabel-root.Mui-focused {
  color: var(--new-charcol-clr) !important;
}
.css-tqkzae-MuiFormLabel-root.Mui-focused {
  color: var(--new-charcol-clr) !important;
}

.sort_by_main {
  width: 100%;
}

.sort-by-categories {
  max-height: 400px;
  overflow-y: auto;
}

.cards_loading_container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22rem;
}

.low_quality_banner_image {
  width: 100%;
  height: 70vh;
  position: absolute;
  top: 0;
  left: 0;
  object-fit:cover;
  /* z-index: -1; */
  object-position: right top;
}

@media screen and (min-width: 768px) {
  .filter-screen{
    height: 100%;
    /* margin-top: 10px; */
    justify-content: center;
    /* padding-inline: 10%; */
  }
  .browse-page-container {
    padding: 0;
    width: 78vw;
    background-color: white;
    margin: 20px auto;
  }
  .dbrowse-container {
    display: flex;
    /* gap: 20px; */
    gap: 50px;
    align-items: flex-start;
  }
  .dbrowse-filter-container {
    width: 260px;
    position: sticky;
    top: 10px;
  }
  .dbrowse-items-list {
    flex: 1;
  }
  /* .browse-page .browse-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    column-gap: 29px;
    row-gap: 50px;
  } */
  .items-control {
    padding: 0;
    margin: 0;
    margin-block: 2vw;
  }
  .items-control {
    /* justify-content: flex-end; */
  }
}

/* @media screen and (max-width: 1100px) {
  .browse-page .browse-items {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
} */


@media screen and (max-width: 1000px) {
  .dbrowse-filter-container {
    width: 250px;
    position: sticky;
    top: 30px;
  }
}

/* @media screen and (max-width:880px) {
  .browse-page .browse-items {
    grid-template-columns: 1fr 1fr 1fr;
  }
} */

@media screen and (max-width:768px) {
  .MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    border-radius: 0 5px 5px 0;
    /* width: 268px; */
    /* width: 60%; */
    padding-right: 0;
    padding-left: 30px;
    /* background-color:#F0E8E3; */
  }
  .MuiFormLabel-root.filter-control-heading {
    font-family: 'Instrument Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.28px;
    color: var(--new-charcol-clr);
    margin-bottom: 12px;
  }

  /* .filter-screen .MuiTypography-root.MuiTypography-body1 {
    color: var(--new-charcol-clr);
    font-family: 'Instrument Sans';
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.36px;
  } */
  .MuiFormControlLabel-root {
    margin-right: 0;
  }
  .price-slider-wrapper {
    padding-right: 30px;
  }
  .price-slider-wrapper .MuiSlider-root{
    color: var(--new-charcol-clr) !important;
    height: 2px;
  }
  .slider-labels {
    padding-right: 13px;
  }
  .filter-apply-wrapper {
    padding: 0;
    padding-right: 30px;
    padding-left: 10px;
  }
  .low_quality_banner_image {
    height: 340px;
  }
}

@media screen and (max-width:620px) {
  .MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    width: 75%;
  }
  .filter-btn {
    margin-right: 0px;
  }
  .filter-btn > img {
    height: 20px;
    width: 20px;
  }
  
  .filter-btn__text {
    font-size: 13px;
  }
}

@media screen and (max-width:350px) {
  .MuiDrawer-paper.MuiDrawer-paperAnchorLeft {
    width: 268px;
  }
}

@media screen and (max-width:1380px) {
  .browse-page .browse-items {
      grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (max-width:1150px) {
  .browse-page .browse-items {
      grid-template-columns: 1fr 1fr;
  }
}
@media screen and (max-width:660px) {
  .browse-page .browse-items {
      grid-template-columns: 1fr;
  }
}