.main-btn-container-one {
    display: flex;
    justify-content: center;
    height: 60px;
    padding: 10px 0;
    border-radius: 5px 5px 0px 0px;
    gap: 20px;
    margin: 10px 0;
    /* margin-bottom: 25px; */
    width: 100%;
}

.main-btn-button {
    flex: 1;
    background-color: #ffff;
    background: var(--primary-btn-clr);
    color: white;
    font-weight: 600;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.main-btn-button.main-btn-button-outline {
    background-color: #fff;
    border: 1px solid var(--primary-btn-clr);
    color: var(--primary-btn-clr);
}

.add_to_cart_btn button {
    flex: 1;
    width: 100%;
    background-color: #ffff;
    background: var(--primary-btn-clr);
    color: white;
    font-weight: 600;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.go_to_cart_btn button {
    flex: 1;
    width: 100%;
    background-color: #ffff;
    color: var(--primary-btn-clr);
    border: 1px solid var(--primary-btn-clr);
    font-weight: 600;
    padding: 10px;
    text-align: center;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
}

.modal-close-button {
    margin: 6px 2px 18px 2px;
    width: -webkit-fill-available;
    height: 35px;
    background-color: #1A385C;
    color: white;
    border-radius: 4px;
    cursor: pointer;
}

.about_item_container {
    display: flex;
    flex-direction: column;
    /* gap: 20px; */
    gap: 31px;
    margin-block: 40px;
}

.about_item_wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.item_heading{
    font-size: 24px;
    font-style: normal;
    /* font-weight: 400; */
    font-weight: 800;
    line-height: 25px;
    letter-spacing: 0px;
}

.item_description{
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
}

.itemdetails-container {
    /* padding-top: 130px; */
    padding-top: 150px;
}

.para_about_cause {
    display: flex;
    flex-direction: column;
    /* gap: 10px; */
    gap: 5px;
    margin-left: 10px;
}


@media screen and (min-width: 768px) {
    .itemdetails-container {
        /* width: 85vw; */
        /* background-color: white; */
        /* margin: 20px auto; */
        width:auto;
        /* padding-top: 160px; */
        padding-top: 200px;
    }

    .itemdetails-content-container {
        display: flex;
        /* gap: 30px; */
        margin-bottom: 30px;
    }

    .itemdetails-content {
        flex: 1;
        /* border: 1px solid #d3d3d3; */
        padding-inline: 20px;
        padding-bottom: 20px;
    }

    .itemdetails-dimages-container {
        flex: 1;
        /* min-height: 420px; */
        display: flex;
        /* justify-content: center; */
        flex-direction: column;
        border-radius: 25px;
    }

    .itemdetails-dimages-image {
        /* height: 250px;
        width: 500px; */
        position: relative;
        height: 430px;
        width: 430px;
    }

    .itemdetails-dimages-right,
    .itemdetails-dimages-left {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: #ececec;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }

    .itemdetails-dimages-left {
        left: 20px;
    }

    .itemdetails-dimages-right {
        right: 20px;
    }

    .itemdetails-dimages-image>img {
        height: 100%;
        width: 100%;
        /* object-fit: contain; */
        /* object-fit: cover; */
        border-radius: 15px;
    }

    .itemdetails-dimages-thumb {
        display: flex;
        margin-top: 20px;
        gap: 10px;
        flex-wrap: wrap;
    }

    .itemdetails-dimages-thumb>div {
        /* height: 70px;
        width: 140px; */
        /* border-radius: 5px; */
        cursor: pointer;
        height: 91px;
        width: 91px !important;
    }

    .itemdetails-dimages-thumb>div#selected {
        border: 1px solid #d3d3d3;
    }

    .itemdetails-dimages-thumb>div>img {
        width: 100%;
        height: 100%;
        /* object-fit: contain; */
        object-fit: cover;
        border-radius: 10px;
    }

    .itemdetails-about-container {
        margin-bottom: 100px;
    }

    .itemdetails-about-container>div {
        margin-top: 30px;
    }

    .itemdetails-about-container h4 {
        font-weight: 600;
        color: var(--primary-blue-clr);
    }

    .itemdetails-about-container p {
        color: #6d6d6d;
        font-size: 0.9rem;
    }

    .item_heading{
        font-size: 28px;
        line-height: 30px;
        /* letter-spacing: 0.46px; */
    }

    .item_description{
        font-size: 20px;
        line-height: 24px;
        /* letter-spacing: 0.36px; */
    }
    
    .about_item_container {
        gap: 24px;
        margin-block: 55px;
    }

}


@media screen and (max-width: 1300px) {
    .itemdetails-dimages-thumb>div {
        width: 100px !important;
    }
}

@media screen and (max-width: 1000px) {
    .itemdetails-dimages-thumb>div {
        height: 80px;
        width: 100px !important;
    }
    .itemdetails-dimages-image {
        /* width: 100%; */
        width: 550px;
        height: 550px;    
    }
    .itemdetails-content-container {
        flex-direction: column;
        gap: 30px;
     }
     .itemdetails-dimages-thumb {
        flex-direction: column;
        margin-top: 0;
     }
     .itemdetails-dimages-container {
        flex-direction: row;
        justify-content: space-evenly;
     }
}

@media screen and (max-width: 600px) {
    .para_about_cause {
        margin-left: 4px;
    }
}
