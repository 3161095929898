.brand_partner_container {
    margin: 0 auto;
    width: 68%;
    display: grid;
    grid-template-rows: auto auto auto;
    padding: 47px 0;
    padding-bottom: 100px;
}

@media screen and (max-width: 768px) {
    .brand_partner_container {
        width: 95%;
    }
}

.brand_partner_container div>img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
}

.brand_partner_container p {
    margin: 44px 0;
    font-weight: 600;
}

.section2 {
    display: grid;
    grid-template-columns: 49% 49%;
    row-gap: 30px;
    column-gap: 20px;
    margin-top: 30px;
}
.section2 h2{
    grid-column: 1/3;
    font-weight: 600;
    font-size: 30px;
}

.section2 > div{
    background: #FFFFFF;
    border: 1px solid #d3d3d3;
}

.card_bottom {
    padding:20px 27px;
}

.card_bottom h3 {
    font-weight: 700;
    font-size: 16px;
}

.card_bottom p {
    font-weight: 400;
    font-size: 14px;
    margin: 15px 0;
}

.section3{
    margin-top: 91px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
}
.section3 h3{
    font-weight: 500;
    font-size: 28px;
}
.contact_us{
    display: flex;
    justify-content: center;
    padding-top: 10px;
}
.image_container{
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 10px;
}
.image_container>img{
    margin-top: 60px;
   border-radius: 25px;
   border: solid #1a385c;
}

@media screen and (max-width: 768px) {
    .brand_partner_main {
        display: none;
    }
}